import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Hero from '../components/hero'
import { MDBContainer, MDBRow } from 'mdbreact'
import ContactMain from '../components/contactMain'
import OfficeDepartments from '../components/cardIconsOffices'

const Contact = ({ data, location }) => {
  const heroImage = data.heroImage
  const post = data.markdownRemark

  return (
    <>
      <Layout>
        <div id="sub-page">
          <SEO
            title="Contact Us"
            description="Want more information on illustro's products and services?  Please get in touch using the contact details or web form provided."
            url={data.site.siteMetadata.siteUrl + location.pathname} 
            image={data.site.siteMetadata.siteUrl + heroImage.childImageSharp?.gatsbyImageData?.images?.fallback?.src}            
          />
          
          <Hero
            class="intro-65"
            image={heroImage.childImageSharp.gatsbyImageData}
            title="Need to get in touch?"
            subtitle="Fill out the form below and we'll get back to you."
            type="contact"
            alt="hand holding a paper plane ready to throw"
          />
        </div>

        <main>
            <ContactMain />

            <section className="bg-light-blue" id="departments">
              <MDBContainer>
                <h3 className="font-alt font-w-700 letter-spacing-1 title-xs-medium title-large pb-5">
                  {post.frontmatter.departments.title}
                </h3>
                {post.frontmatter.departments.contactdeets && (
                  <MDBRow>
                    <>
                      {post.frontmatter.departments.contactdeets.map((details, index) => {
                          return (
                            <OfficeDepartments
                              key={index}
                              depttitle={details.depttitle}
                              tel={details.tel}
                              email={details.email}
                              suptext={details.suptext}
                              image={details.image.childImageSharp.gatsbyImageData}
                              alttext={details.alttext}
                            />
                          )
                        }
                      )}
                    </>
                  </MDBRow>
                )}
              </MDBContainer>
          </section>
        </main>
      </Layout>
    </>
  )
}
export default Contact

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    markdownRemark(
      fields: {}
      frontmatter: { template: { eq: "other" }, name: { eq: "contact" } }
    ) {
      html
      frontmatter {
        meta {
          title
          description
        }
        section {
          title
          subtitle
          description
        }
        departments {
          title
          contactdeets {
            depttitle
            tel
            email
            suptext
            alttext
            image {
            childImageSharp {
              gatsbyImageData(width: 85, quality: 90) 
            }
           }
          }
        }              
      }
      html
    }
    heroImage: file(name: { eq: "contact-us" }) {
      childImageSharp {
        gatsbyImageData(quality: 90)
      }
    }
  }
`
